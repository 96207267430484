import React, { useEffect, useMemo } from "react";
import Head from "next/head";
import {
  AppState,
  AppStateProvider,
  DialogContextProvider,
  Footer,
  Header,
  LinkContext,
  useAppState,
  WindowDimensionsProvider,
  LocaleModalProps,
  AppStateProviderProps,
} from "@travellocal/ui";
import { getStaticPageContent, NextLink } from "..";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { getCountriesWithPublishedTrips } from "../../utils/api/site2";
import { getSubscribe } from "utils/api/cms";
import { GetStaticPropsContext } from "next";
import { useLocaleModal } from "utils/hooks/use-locale-modal";
import { DataTrackingIdsFooter, DataTrackingIdsHeader } from "utils/types";
import { isLocale } from "@travellocal/utils";

const LocaleModal = dynamic<LocaleModalProps>(() =>
  import("@travellocal/ui").then((mod) => mod.LocaleModal)
);

export interface AlternatePage {
  /** UTS string locale of the alternate page, all lowercase. */
  locale: string;
  /** Fully qualified URL of the alternate page. */
  url: string;
  /**
   * If true, then the URL is only used for the language switcher
   * - it's a fallback for when this locale is selected, but it's not an
   * alternate version of the same page.
   * To support scenario:
   * 1. user is on an article page
   * 2. selects DE locale
   * 3. user taken to DE articles archive
   */
  isFallback?: boolean;
}

export interface PageProps {
  title: string;
  className?: string;
  containerClassName?: string;
  countries: Awaited<ReturnType<typeof getCountriesWithPublishedTrips>>;
  initialAppState: Partial<AppState>;
  children: React.ReactNode;
  onSessionLoad?: (user: AppState["user"]) => void;
  /**
   * Full list of alternate locales for this page.
   * N.B. According to Google this must include the current page as well.
   * https://developers.google.com/search/docs/specialty/international/localized-versions#sitemap
   **/
  alternatePages?: AlternatePage[];
  meta: {
    author?: string;
    canonicalUrl: string;
    description?: string;
    imageSrc?: string;
    locale: string;
    publishedDate?: string;
    title: string;
    type?: null | `article`;
  };
  subscribeResponse?: {
    subscribeBodyText: string;
    subscribeText: string;
  };
  socialMedia: Array<{
    label: string;
    platform: string;
    url: string;
  }>;
  shouldShowYourTripButton?: boolean;
  hasMainNavigation?: boolean;
  hasUtilityButtons?: boolean;
  showMinimalFooter?: boolean;
  hideTrust?: boolean;
  dataTrackingIdsHeader?: DataTrackingIdsHeader;
  dataTrackingIdsFooter?: DataTrackingIdsFooter;
}

/**
 * Bundle of all the provider components we need to run the app.
 */
const Providers: React.FC<AppStateProviderProps> = ({ initialAppState, children }) => {
  // Force a remount of AppStateProvider when rendering a static page in fallback mode;
  // Otherwise it will initialise AppState empty and won't rerender when data is loaded in.
  const router = useRouter();

  return (
    <AppStateProvider initialAppState={initialAppState} key={router.isFallback ? 0 : 1}>
      <LinkContext.Provider value={{ Component: NextLink, localePrefix: true }}>
        <WindowDimensionsProvider>
          <DialogContextProvider appRootId="__next">{children}</DialogContextProvider>
        </WindowDimensionsProvider>
      </LinkContext.Provider>
    </AppStateProvider>
  );
};

/**
 * Wrapper component for pages, built with Container. Should exist once per route.
 *
 * @status development
 */
const InnerPage: React.FC<PageProps> = ({
  alternatePages,
  countries,
  title,
  children,
  containerClassName,
  meta,
  onSessionLoad,
  subscribeResponse,
  socialMedia,
  shouldShowYourTripButton,
  hasMainNavigation = true,
  hasUtilityButtons = true,
  showMinimalFooter = false,
  hideTrust = false,
  dataTrackingIdsHeader,
  dataTrackingIdsFooter,
}) => {
  const { loadUserSession, appState, setCookie } = useAppState();
  const { showLocaleModal, closeLocaleModal, setLocaleCallback } = useLocaleModal({
    alternatePages,
    appState,
    setCookie,
  });
  const mappedCountries = useMemo(
    () =>
      countries?.map(({ id, href, name }) => ({
        id,
        href,
        label: name,
      })),
    [countries]
  );
  const { t } = useTranslation();
  const router = useRouter();

  const subscribeTextFallback = t(
    "web:Subscribe_body",
    "Join our newsletter for more inspiration, local expertise, and updates on how we’re making travel a force for good."
  );
  const subscribeTitleFallback = t("web:Subscribe_title", "Reimagine travel with us");

  // User could be logged into either Site 1 or Site 2
  useEffect(() => {
    if (!router.isFallback) {
      loadUserSession().then((user) => {
        onSessionLoad?.(user);
      });
    }
  }, [router.isFallback]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="title" property="og:title" content={meta.title || title} />
        <link rel="canonical" href={meta.canonicalUrl} />
        <meta name="url" property="og:url" content={meta.canonicalUrl} />
        <meta
          name="image"
          property="og:image"
          content={
            meta?.imageSrc ? meta.imageSrc : `${process.env.NEXT_PUBLIC_WEB_ROOT}/api/og-image`
          }
        />
        {meta.type && <meta name="type" property="og:type" content={meta.type} />}
        {meta.description && (
          <>
            <meta name="description" property="og:description" content={meta.description} />
            <meta name="twitter:description" content={meta.description} />
          </>
        )}
        {meta.locale && <meta name="locale" property="og:locale" content={meta.locale} />}
        {meta.author && (
          <meta name="author" property="article:author" content={meta.author || "TravelLocal"} />
        )}
        {meta.publishedDate && (
          <>
            <meta
              name="og:article:published_time"
              property="og:article:published_time"
              content={meta.publishedDate}
            />
            <meta
              name="article:published_time"
              property="article:published_time"
              content={meta.publishedDate}
            />
          </>
        )}
        <meta property="twitter:site" content="@travellocal" />
        {(alternatePages ?? [])
          .filter((x) => !x.isFallback)
          .map((alt) => (
            <link key={alt.locale} rel="alternate" hrefLang={alt.locale} href={alt.url} />
          ))}
      </Head>

      <Header
        countries={mappedCountries}
        onLocaleSelect={setLocaleCallback}
        currentPath={router.asPath}
        faqUrl={t("routes:faq")}
        shouldShowYourTrip={shouldShowYourTripButton}
        hasMainNavigation={hasMainNavigation}
        hasUtilityButtons={hasUtilityButtons}
        dataTrackingIds={dataTrackingIdsHeader}
      />

      <main className={containerClassName}>{children}</main>

      <Footer
        subscribeTitle={subscribeResponse?.subscribeText ?? subscribeTitleFallback}
        subscribeBodyText={subscribeResponse?.subscribeBodyText ?? subscribeTextFallback}
        socialMedia={socialMedia}
        bCorpLink="https://reimagine.travellocal.com/en/certified-b-corporation"
        hideTrust={hideTrust}
        isMinimal={showMinimalFooter}
        dataTrackingIds={{
          ...dataTrackingIdsFooter,
          subscribeWrapper: {
            ...dataTrackingIdsFooter?.subscribeWrapper,
            firstName: "frm.subscribe.first_name.footer",
            email: "frm.subscribe.email.footer",
          },
        }}
      />
      {showLocaleModal && <LocaleModal onClick={setLocaleCallback} onClose={closeLocaleModal} />}
    </>
  );
};

export const getStaticProps = async (context: GetStaticPropsContext) => {
  if (!isLocale(context.locale)) {
    return {
      notFound: true,
    };
  }

  const pageProps = await getStaticPageContent(context);
  const currentLocale =
    pageProps.initialAppState.locale ?? pageProps.initialAppState.detectedLocale;
  const subscribeResponse = await getSubscribe([currentLocale]);

  return {
    revalidate: 600,
    props: {
      ...pageProps,
      subscribeResponse: subscribeResponse,
    },
  };
};

export const Page: React.FC<PageProps> = (props) => (
  <Providers initialAppState={props.initialAppState}>
    <InnerPage
      subscribeResponse={
        typeof props.children === "object" && "props" in props.children
          ? props.children.props.subscribeResponse?.[0]?.globalContent?.subscribeComponent
          : undefined
      }
      {...props}
    />
  </Providers>
);
