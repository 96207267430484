import React from "react";
import Link from "next/link";

/**
 * Wrapper around Next's Link component for use with @travellocal/ui/LinkContext.
 */
export const NextLink: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & { href: string }
> = ({ href, children, ...htmlProps }) => {
  // Disable prefetching as it's not playing nicely with CORS from Site 2
  // TODO re-enable for known Site 3 pages
  return (
    <Link href={href} passHref prefetch={false} {...htmlProps}>
      {children}
    </Link>
  );
};
